<template>
	<div>
		<!-- page-wrapper Start-->
		<div class="page-wrapper">
			<b-card>
			<div class="error-wrapper">
				<div v-if="message == 'Success'" class="container"><img class="img-100" src="../assets/images/other-images/smily.png" alt="">
					<div class="">
						<h2 class="font-success">Payment Success.</h2>
					</div>
					<div class="col-md-8 offset-md-2">
						<p class="sub-content">You have successfully activated premium plan. Thank You...</p>
					</div>
					<div><router-link class="btn btn-info-gradien btn-lg" :to="{ path: '/' }">BACK TO HOME PAGE</router-link></div>
				</div>
                <div v-if="message == 'Error'" class="container"><img class="img-100" src="../assets/images/other-images/sad1.png" alt="">
					<div class="">
						<h2 class="font-danger">Payment Failed.</h2>
					</div>
					<div class="col-md-8 offset-md-2">
						<p class="sub-content">Plan Activation Failed. Plase try Again.</p>
					</div>
					<div><router-link class="btn btn-info-gradien btn-lg" :to="{ path: '/' }">BACK TO HOME PAGE</router-link></div>
				</div>
			</div>
		</b-card>
		</div>
	</div>
</template>
<script>
export default
{
name:"notifyPage",
data(){
    return{
        message : ''
    }
},
mounted(){
	const message = this.$route.query.message
    if(message)this.message = message  
}
}
</script>